import {classes} from '../Components/CustomerDetailsOpen.st.css';
import {FIELDS_GAP} from '../../../../constants';
import {CustomerDetailsForm as ConsolidatedCustomerDetailsForm} from '../../../../Form/CustomerDetailsForm/CustomerDetailsForm';
import React, {useEffect} from 'react';
import {CustomerDetailsDataHook} from '../Components/CustomerDetailsOpen';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useCustomerDetailsData} from '../WithCustomerDetailsData/WithCustomerDetailsData';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {getCountryFromAddressFormValues} from '../../../../Form/form.utils';
import {TextField} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../../common/constants';

export const CustomerDetailsForm = () => {
  const {
    formsStore: {areFormsLoaded, doesFormHaveSubscription},
    checkoutStore: {checkout},
    memberStore: {isMember, currentUserEmail},
  } = useControllerProps();

  const {customerDetailsFormData, updateContactCountry} = useCustomerDetailsData();
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();

  const country = getCountryFromAddressFormValues(customerDetailsFormData.formValues);
  const shouldShowDisabledEmail =
    isMember &&
    experiments.enabled(SPECS.UseNewSubscriptionCheckboxInCustomerDetailsForm) &&
    doesFormHaveSubscription &&
    (currentUserEmail || checkout.buyerInfo.email);

  useEffect(
    () => {
      void updateContactCountry(country);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [country]
  );

  if (!areFormsLoaded) {
    return <FormLoader />;
  }

  return (
    <div
      data-hook={CustomerDetailsDataHook.customerDetailsForm}
      className={classes.forms}
      style={{
        gap: `${FIELDS_GAP}px`,
      }}>
      {shouldShowDisabledEmail && (
        <TextField
          data-hook={CustomerDetailsDataHook.disabledMemberEmail}
          className={classes.disbaledEmail}
          label={localeKeys.ecom.email.label()}
          disabled
          value={currentUserEmail ?? checkout.buyerInfo.email}
        />
      )}
      <ConsolidatedCustomerDetailsForm {...customerDetailsFormData} />
    </div>
  );
};
